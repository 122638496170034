import React from 'react';
import {
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography
} from '@mui/material';
import { logPostHogEvent } from '@utils/index';
import trackUse from '@utils/trackUse';

const PercentageFilter = ({
  percentOff,
  setPercentOff
}: {
  percentOff: number | null;
  setPercentOff: (value: number | null) => void;
}) => {
  const applyPercentageSelection = (pc: number | null) => {
    logPostHogEvent('filter-chip-percentage', {
      value: `${pc}`,
      type: 'ACTION'
    });
    trackUse({
      item: `filter-chip-percentage`,
      value: `${pc}`,
      type: 'ACTION'
    });

    setPercentOff(pc);
  };

  return (
    <>
      <Box
        sx={{
          margin: '8px 0'
        }}
      >
        <Typography variant="overline">Percentage</Typography>
        <RadioGroup
          value={percentOff || 'null'}
          onChange={(event) =>
            applyPercentageSelection(
              event.target.value !== 'null' ? Number(event.target.value) : null
            )
          }
        >
          {[null, 30, 40, 50, 60, 70, 80].map((percentage) => (
            <FormControlLabel
              key={percentage}
              value={percentage}
              control={<Radio />}
              label={
                percentage === null ? `No filter` : `${percentage}% off or more`
              }
            />
          ))}
        </RadioGroup>
      </Box>
    </>
  );
};

export default PercentageFilter;
