export default {
  skeletonTextActions: {
    margin: '8px 0 8px 8px'
  },
  cardContent: {
    display: 'table',
    padding: '8px 8px 36px 8px !important',
    // less than 400 px
    '@media (max-width: 380px)': {
      padding: '8px 8px 40px 8px !important'
    }
  },
  skeletonCardContent: {
    display: 'inline-block',
    minWidth: 'calc(100% - 160px)',
    '@media (max-width: 380px)': {
      minWidth: '94%'
    }
  },
  image: {
    maxHeight: '140px',
    display: 'none'
  },
  imageMobile: {
    maxWidth: '136px',
    maxHeight: '140px',
    display: 'block',
    borderRadius: '3px'
  },
  skeletonImageMobile: {
    willChange: 'transform',
    transform: 'scale(1, 1)'
  },
  imageWrapper: {
    maxHeight: '140px',
    minHeight: '140px',
    // margin: '0px auto',
    // 10px auto 8px 0px!important
    // former: 0px auto 8px 0px!important
    margin: '10px auto 8px 0px !important',
    textAlign: 'center',
    borderRadius: '3px',
    // minWidth: '100%',
    minWidth: '140px',
    width: '140px',
    display: 'inline-block',
    float: 'left',
    // less than 380px
    '@media (max-width: 380px)': {
      float: 'none !important',
      display: 'flex !important',
      width: '100% !important'
    }
  },
  actionButton: {
    backgroundColor: 'transparent !important'
  },
  actionButtons: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    textAlign: 'right',
    padding: '8px 8px 0 8px !important'
  },
  mobileDate: {
    fontSize: '11px !important'
  },
  cardRoot: {
    transition: 'none !important',
    animation: 'none !important',
    display: 'flex',
    flexDirection: 'column',
    // Ensure cards stretch to the same height
    flex: '1 0 auto',
    position: 'relative',
    maxWidth: 'calc(50% - 14px)',
    minWidth: 'calc(50% - 14px)',
    minHeight: '160px',
    textAlign: 'left',
    margin: '8px 8px 0 8px',
    // 2 per row
    // '@media (min-width: 1000px) and (max-width: 1419px)': {
    '@media (min-width: 1000px)': {
      '&:nth-of-type(2n)': {
        marginLeft: 0
      }
    },
    // 1 per row
    '@media (max-width: 1000px)': {
      maxWidth: 'calc(100% - 18px)',
      minWidth: 'calc(100% - 18px)',
      minHeight: 'initial'
    }
    // 3 per row
    // '@media (min-width: 1420px)': {
    //   maxWidth: 'calc(33.3% - 12px)',
    //   minWidth: 'calc(33.3% - 12px)',
    //   // nth type 2 of 3 and 3 of 3
    //   '&:nth-of-type(3n)': {
    //     marginLeft: 0
    //   },
    //   '&:nth-of-type(3n - 1)': {
    //     marginLeft: 0
    //   }
    // }
  },
  cardRoot2: {
    transition: 'none !important',
    animation: 'none !important',
    display: 'flex',
    flexDirection: 'column',
    // Ensure cards stretch to the same height
    flex: '1 0 auto',
    position: 'relative',
    maxWidth: 'calc(50% - 14px)',
    minWidth: 'calc(50% - 14px)',
    minHeight: '160px',
    textAlign: 'left',
    margin: '8px 8px 0 8px',
    // 2 per row
    // '@media (min-width: 1000px) and (max-width: 1419px)': {
    '@media (min-width: 600px)': {
      '&:nth-of-type(2n)': {
        marginLeft: 0
      }
    },
    // 1 per row
    '@media (max-width: 600px)': {
      maxWidth: 'calc(100% - 18px)',
      minWidth: 'calc(100% - 18px)',
      minHeight: 'initial'
    },
    // 3 per row
    '@media (min-width: 920px)': {
      maxWidth: 'calc(33.3% - 12px)',
      minWidth: 'calc(33.3% - 12px)',
      // nth type 2 of 3 and 3 of 3
      '&:nth-of-type(3n)': {
        marginLeft: 0
      },
      '&:nth-of-type(3n - 1)': {
        marginLeft: 0
      }
    }
  },
  chipWrapper: {
    marginBottom: '0.35em',
    marginTop: '0.35em',
    display: 'block',

    '@media (max-width: 48rem)': {
      display: 'block'
    }
  }
};
