import React from 'react';
import {
  Box,
  Chip,
  Slider,
  useTheme,
  useMediaQuery,
  Typography
} from '@mui/material';
import { formatPrice, logPostHogEvent } from '@utils/index';
import trackUse from '@utils/trackUse';

const transformValueFunc = (value: number): number => {
  // Convert the linear slider value to the custom "log-like" scale.
  if (value <= 25) return value * 0.2; // $0 - $5
  if (value <= 50) return (value - 25) * 0.4 + 5; // $5 - $10
  if (value <= 75) return (value - 50) * 0.8 + 10; // $10 - $20
  if (value <= 100) return (value - 75) * 1.2 + 20; // $20 - $50
  if (value <= 150) return (value - 100) * 2.6 + 50; // $50 - $150
  if (value <= 175) return (value - 150) * 10 + 150; // $150 - $400
  return (value - 175) * 64 + 400; // $400 - $2000
};

const transformValue = (value: number): number => {
  return Math.round(transformValueFunc(value));
};

const inverseTransformValueFunc = (value: number): number => {
  // Convert the "log-like" scale value back to the linear slider value.
  if (value <= 5) return value / 0.2; // $0 - $5
  if (value <= 10) return (value - 5) / 0.4 + 25; // $5 - $10
  if (value <= 20) return (value - 10) / 0.8 + 50; // $10 - $20
  if (value <= 50) return (value - 20) / 1.2 + 75; // $20 - $50
  if (value <= 150) return (value - 50) / 2.6 + 100; // $50 - $150
  if (value <= 400) return (value - 150) / 10 + 150; // $150 - $400
  return (value - 400) / 64 + 175; // $400 - $2000
};

const inverseTransformValue = (value: number): number => {
  return Math.round(inverseTransformValueFunc(value));
};

const PriceFilter = ({
  underPrice,
  overPrice,
  setUnderPrice,
  setOverPrice
}: {
  underPrice: number | null;
  overPrice: number | null;
  setUnderPrice: (value: number | null) => void;
  setOverPrice: (value: number | null) => void;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const localUnderPrice = inverseTransformValue(underPrice || 2000);
  // localOverPrice is the slider position:
  const localOverPrice = inverseTransformValue(overPrice || 0);

  const applyPriceRange = (
    newOverPrice: number | null,
    newUnderPrice: number | null
  ) => {
    logPostHogEvent('filter-chip-price', {
      value: `${newOverPrice || 0} - ${newUnderPrice || 2000}`,
      type: 'ACTION'
    });
    trackUse({
      item: `filter-chip-price`,
      value: `${newOverPrice || 0} - ${newUnderPrice || 2000}`,
      type: 'ACTION'
    });

    const finalOverPrice =
      newOverPrice !== 0 ? transformValue(newOverPrice) : null;
    const finalUnderPrice =
      newUnderPrice !== 200 ? transformValue(newUnderPrice) : null;

    setOverPrice(finalOverPrice);
    setUnderPrice(finalUnderPrice);
  };

  const handleSliderChange = (_event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      applyPriceRange(newValue[0], newValue[1]);
    }
  };

  const renderSlider = () => {
    return (
      <Slider
        value={[localOverPrice, localUnderPrice]}
        onChange={handleSliderChange}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `${formatPrice(transformValue(value))}`} // Custom tooltip text
        min={0}
        max={200} // Linear scale maximum
        step={1}
        sx={
          isMobile
            ? {
                '& .MuiSlider-markLabel': {
                  fontSize: '12px' // Adjust the size of the mark labels
                },
                '& .MuiSlider-valueLabel': {
                  fontSize: '14px' // Adjust the size of the value label (tooltip)
                }
              }
            : {}
        }
        marks={
          isMobile
            ? [
                { value: 0, label: '$0' },
                { value: 100, label: `${formatPrice(transformValue(100))}` },
                { value: 200, label: '$2,000+' }
              ]
            : [
                { value: 0, label: '$0' },
                { value: 200, label: '$2,000+' }
              ]
        }
      />
    );
  };

  return (
    <Box marginBottom="4px">
      <Typography variant="overline">Price</Typography>
      <Box>
        <Box>
          {[1, 2, 5, 10, 20, 50].map((price) => {
            return (
              <Chip
                key={price}
                sx={{
                  marginRight: '6px',
                  marginBottom: '6px'
                }}
                label={`Under ${formatPrice(price)}`}
                onClick={() => {
                  applyPriceRange(0, inverseTransformValue(price));
                }}
              />
            );
          })}
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            // maxWidth: isMobile ? '100%' :'85%',
            margin: isMobile ? '8px 20px 8px 8px' : '0px 30px 0px 10px'
          }}
        >
          {' '}
          <Box>
            {' '}
            <Typography variant="caption" color="text.secondary">
              {`Price Range: ${formatPrice(
                transformValue(localOverPrice || 0)
              )} — ${
                transformValue(localUnderPrice || 2000) === 2000
                  ? '$2,000+'
                  : formatPrice(transformValue(localUnderPrice || 2000))
              }`}
            </Typography>
          </Box>
          {renderSlider()}
        </Box>
      </Box>
    </Box>
  );
};

export default PriceFilter;
