import React, { memo, useMemo } from 'react';
import { Card } from '@mui/material';
import { useTheme } from '@mui/system';
import colors from '@utils/colors';
import { DealPostType } from '@types';
import { getCurrentDealEventStrObj } from '@utils/index';
import { useGetFavorites } from '@hooks/useFavorites';
import classes from '../styles';
import DealInnerCard from './components/DealInnerCard';

export interface DealCardProps extends DealPostType {
  onlyCoupons: boolean;
  toggleOnlyCoupons: () => void;
  tag?: string;
  index: number;
}

const DealCard = memo((props: DealCardProps) => {
  const { ASIN } = props;
  const { data: userFavorites } = useGetFavorites();
  const currentEventObj = getCurrentDealEventStrObj();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const showEventBorder = useMemo(() => {
    return (
      currentEventObj &&
      (props.listingMode || '')
        ?.toLowerCase()
        ?.includes(currentEventObj.value.toLowerCase())
    );
  }, [props.listingMode, currentEventObj]);

  const isFavorite = useMemo(() => {
    return ASIN && userFavorites?.includes(ASIN);
  }, [ASIN, userFavorites]);

  const cardClasses = useMemo(() => {
    return {
      ...classes.cardRoot,
      ...(props.isBestSeller
        ? {
            border: `2px solid ${
              isDarkMode ? 'transparent' : theme.palette.popularColor
            }`,
            boxSizing: 'border-box'
          }
        : {}),
      ...(showEventBorder && currentEventObj?.shouldShowDeals
        ? {
            border: `2px solid ${
              currentEventObj?.dealHighlightColor || colors.jdbPurple
            }`,
            boxSizing: 'border-box'
          }
        : {}),
      ...(isFavorite
        ? {
            border: `2px solid ${
              isDarkMode ? 'transparent' : theme.palette.favoriteColor
            }`,
            boxSizing: 'border-box'
          }
        : {})
    };
  }, [
    classes,
    props.isBestSeller,
    showEventBorder,
    isFavorite,
    currentEventObj
  ]);

  return (
    <Card
      key={ASIN}
      raised
      sx={{
        ...cardClasses,
        boxSizing: 'border-box',
        transform: 'translateZ(0)', // Force GPU rendering
        willChange: 'transform' // Hint to browser
      }}
    >
      <DealInnerCard {...props} />
    </Card>
  );
});

export default DealCard;
