/* eslint-disable react/no-unused-prop-types */
import React, { useMemo } from 'react';
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Button,
  IconButton,
  Chip,
  Box,
  useTheme
} from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { DealPostType } from '@types';
import { useGetFavorites } from '@hooks/useFavorites';
import colors from '@utils/colors';
import {
  formatPrice,
  //   getProductLink,
  getCurrentDealEventStrObj,
  isDateWithinLast24Hours
  //   parseTitle,
  //   getPostPrice
} from '@utils/index';
import classes from '../styles';
// Define the type for the product object

interface DealCardProps extends DealPostType {
  index: number;
  toggleOnlyCoupons: () => void;
  onlyCoupons: boolean;
  tag?: string;
}

const DealCard = (props: DealCardProps) => {
  const {
    dateFirstActive,
    ASIN,
    title,
    image,
    couponPercentage,
    couponFixed,
    finalPrice,
    listPrice,
    socialBought,
    postText
  } = props;
  const { data: userFavorites } = useGetFavorites();
  const currentEventObj = getCurrentDealEventStrObj();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const showEventBorder = useMemo(() => {
    return (
      currentEventObj &&
      (props.listingMode || '')
        ?.toLowerCase()
        ?.includes(currentEventObj.value.toLowerCase())
    );
  }, [props.listingMode, currentEventObj]);

  const isFavorite = useMemo(() => {
    return ASIN && userFavorites?.includes(ASIN);
  }, [ASIN, userFavorites]);

  const cardClasses = useMemo(() => {
    return {
      ...classes.cardRoot2,
      ...(props.isBestSeller
        ? {
            border: `2px solid ${
              isDarkMode ? 'transparent' : theme.palette.popularColor
            }`,
            boxSizing: 'border-box'
          }
        : {}),
      ...(showEventBorder && currentEventObj?.shouldShowDeals
        ? {
            border: `2px solid ${
              currentEventObj?.dealHighlightColor || colors.jdbPurple
            }`,
            boxSizing: 'border-box'
          }
        : {}),
      ...(isFavorite
        ? {
            border: `2px solid ${
              isDarkMode ? 'transparent' : theme.palette.favoriteColor
            }`,
            boxSizing: 'border-box'
          }
        : {})
    };
  }, [
    classes,
    props.isBestSeller,
    showEventBorder,
    isFavorite,
    currentEventObj
  ]);

  const isNew = !!(
    dateFirstActive && isDateWithinLast24Hours(new Date(dateFirstActive))
  );
  return (
    <Card
      key={ASIN}
      raised
      sx={{
        ...cardClasses,
        boxSizing: 'border-box',
        transform: 'translateZ(0)', // Force GPU rendering
        willChange: 'transform', // Hint to browser
        // maxWidth: 345,
        boxShadow: 3,
        borderRadius: 2,
        position: 'relative'
      }}
    >
      {/* Discount Badge */}
      <Chip
        label="50% OFF"
        color="error"
        size="small"
        sx={{ position: 'absolute', top: 10, right: 10, fontWeight: 'bold' }}
      />

      {/* New Badge */}
      {isNew && (
        <Chip
          label="New"
          color="success"
          size="small"
          sx={{ position: 'absolute', top: 10, left: 10, fontWeight: 'bold' }}
        />
      )}

      {/* Product Image */}
      <CardMedia
        component="img"
        height="200"
        image={image}
        alt={title}
        sx={{ objectFit: 'cover' }}
      />

      {/* Product Details */}
      <CardContent>
        <Typography gutterBottom variant="subtitle1" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {postText}
        </Typography>

        {/* Pricing Section */}
        <Box sx={{ mt: 2 }}>
          <Typography
            variant="h5"
            component="span"
            sx={{ fontWeight: 'bold', color: 'primary.main' }}
          >
            {formatPrice(finalPrice)}
          </Typography>
          <Typography
            variant="body2"
            component="span"
            sx={{
              textDecoration: 'line-through',
              ml: 1,
              color: 'text.secondary'
            }}
          >
            {formatPrice(listPrice)}
          </Typography>
          <Typography variant="body2" color="error" sx={{ mt: 1 }}>
            You save {formatPrice(listPrice - finalPrice)}
          </Typography>
        </Box>

        {/* Coupon Badge */}
        {(couponFixed || couponPercentage) && (
          <Chip
            label={`${couponFixed || couponPercentage} Off Coupon`}
            color="info"
            size="small"
            sx={{ mt: 1 }}
          />
        )}

        {/* Purchase Info */}
        {socialBought ? (
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            {socialBought}
          </Typography>
        ) : null}
      </CardContent>

      {/* Action Buttons */}
      <CardActions sx={{ justifyContent: 'space-between', p: 2 }}>
        <Button variant="contained" color="primary" size="small">
          Buy Now
        </Button>
        <IconButton size="small">
          <FavoriteBorderIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default DealCard;
